import React from "react";

const ModalOne = () => {
  return (
    <div className="popup_informations">
      <div className="image">
        <img src="img/thumbs/4-3.jpg" alt="" />
        <div
          className="main"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/service/1.jpg"
            })`,
          }}
        ></div>
      </div>
      {/* End big image */}

      <div className="description">
        <h3>Front-End Web Development Breakdown</h3>
        <p>
          In a world where user experience is king, front-end web development
          stands as the cornerstone of any successful online interaction. It's
          not just about having a functional website anymore; it's about how the
          website feels to the end user. Here are key considerations that every
          front-end developer should keep in mind.
        </p>
        <p>
          The Peculiarities of Modern Browsers Today's web landscape is more
          fragmented than ever, making it essential to consider cross-browser
          compatibility. With an array of browsers and devices, coding for
          uniform performance is both challenging and rewarding.
        </p>
        <h3>The Importance of Responsiveness</h3>
        <p>
          Responsive design isn't a luxury; it's a necessity. With the
          proliferation of mobile devices, your website needs to look and
          function well on screens of all sizes. This adaptability ensures a
          seamless user experience, no matter the device used.
        </p>
        <h3>Performance Optimization</h3>
        <p>
          Website speed and performance are not just backend concerns; they're
          front-end ones as well. Techniques like lazy loading images, async
          script loading, and effective caching strategies can make a dramatic
          difference in a website's user experience.{" "}
        </p>
        <p>
          <h3>Code Quality and Maintainability</h3>
          <p>
          Clean and optimized code isn't just for the back-end. The front-end requires the same level of rigor. Writing maintainable code that can be easily updated or scaled is crucial for the long-term success of a project.
          </p>
          These topics underscore the multi-faceted nature of front-end development, emphasizing the need for a broad skill set, from coding to design to performance optimization.
        </p>
      </div>
    </div>
  );
};

export default ModalOne;

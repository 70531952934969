import React from "react";

const skillsContent = [
  { name: "React.Js" }, // Mentioned in CV
  { name: "React Native" }, // Mentioned in CV
  { name: "Node.Js" }, // Mentioned in CV
  { name: "Vue.js" }, // Mentioned in CV
  { name: "HTML/CSS" }, // Mentioned in CV
  { name: "AWS" }, // Mentioned in CV
  { name: "Serverless" }, // Mentioned in CV
  { name: "microservices" }, // Mentioned in CV
  { name: "Google Cloud Platform (GCP)" }, // Extracted from CV
  { name: "Stripe" }, // Extracted from CV
  { name: "CI/CD pipelines" }, // Extracted from CV
  { name: "Django" }, // Extracted from CV
  { name: "MongoDB" }, // Extracted from CV
  { name: "Git" }, // Extracted from CV
  { name: "Jira" }, // Extracted from CV
  { name: "TypeScript" }, // Extracted from CV
  { name: "Redux" }, // Extracted from CV
  { name: "Socket.io" }, // Extracted from CV
  { name: "Cloud Functions" }, // Extracted from CV
  { name: "Spring Boot" }, // Extracted from CV
  { name: "MySQL" }, // Extracted from CV
  { name: "Docker" }, // Extracted from CV
  { name: "Express.js" }, // Extracted from CV
];

const Skills = () => {
  return (
    <>
      {skillsContent.map((val, i) => (
        <div className="progress_inner" key={i}>
          <span className="label">{val.name}</span>
        </div>
      ))}
    </>
  );
};

export default Skills;

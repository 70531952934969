import React from "react";

const ModalFour = () => {
  return (
    <div className="popup_informations">
      <div className="image">
        <img src="img/thumbs/4-3.jpg" alt="" />
        <div
          className="main"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/service/4.jpg"
            })`,
          }}
        ></div>
      </div>
      {/* End big image */}

      <div className="description">
      <h3>Code Unit Testing Breakdown</h3>
        <p>
          Code unit testing is a pivotal practice in software development,
          designed to validate the smallest units of testable code in an
          application. Here are fundamental elements to focus on when conducting
          unit tests.
        </p>
        <h3>Test Coverage</h3>
        <p>
          Achieving high test coverage ensures that your codebase is thoroughly
          checked for bugs and regressions. It helps developers to refactor code
          and add new features with confidence.
        </p>
        <h3>Isolation and Mocking</h3>
        <p>
          Effective unit tests isolate the code being tested, often using
          mocking frameworks to replicate dependencies. This isolation provides
          a reliable environment for identifying the cause of any test failure.
        </p>
        <h3>Test Driven Development (TDD)</h3>
        <p>
          TDD is a development practice where writing failing tests precedes
          writing the code itself. It's an effective method for achieving clean,
          functional, and bug-free code.
        </p>
        <h3>Continuous Integration</h3>
        <p>
          Unit tests should be part of a continuous integration pipeline,
          ensuring that tests are run automatically upon each commit, thereby
          catching issues early in the development cycle.
        </p>
        <h3>Documentation and Code Comments</h3>
        <p>
          Well-documented tests serve as a form of live documentation. Code
          comments and descriptive test names help future developers understand
          the purpose and mechanism of each test case.
        </p>
        <p>
          By focusing on these core principles, developers can produce a robust
          and maintainable codebase that stands up to future changes and
          expansions.
        </p>
      </div>
    </div>
  );
};

export default ModalFour;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Testimonial() {
  var settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 993,
        settings: {
          arrow: false,
          slidesToShow: 1,
          speed: 300,
        },
      },
    ],
  };

  const testimonialContent = [
    {
      imgTst: "img/testimonials/1tst.png",
      name: "Micah",
      designation: "Data visualization Project",
      delayAnimation: "",
    },
    {
      imgTst: "img/testimonials/2tst.png",
      name: "Reservet",
      designation: "Data visualization Project",
      delayAnimation: "100",
    },
    {
      imgTst: "img/testimonials/3tst.png",
      name: "Tstenko",
      designation: "Data visualization Project",
      delayAnimation: "200",
    },
    {
      imgTst: "img/testimonials/4tst.png",
      name: "Micah",
      designation: "Data visualization Project",
      delayAnimation: "300",
    },
    {
      imgTst: "img/testimonials/5tst.png",
      name: "Alex lee",
      designation: "Data visualization Project",
      delayAnimation: "400",
    },
    {
      imgTst: "img/testimonials/6tst.png",
      name: "Reservet",
      designation: "Data visualization Project",
      delayAnimation: "500",
    },
    {
      imgTst: "img/testimonials/7tst.png",
      name: "Erik nilsson",
      designation: "Data visualization Project",
      delayAnimation: "600",
    },
    {
      imgTst: "img/testimonials/8tst.png",
      name: "Mustafa elrayah",
      designation: "Data visualization Project",
      delayAnimation: "700",
    },
    {
      imgTst: "img/testimonials/9tst.png",
      name: "Froop666",
      designation: "Data visualization Project",
      delayAnimation: "800",
    },
    {
      imgTst: "img/testimonials/10tst.png",
      name: "Micah",
      designation: "Data visualization Project",
      delayAnimation: "900",
    },
    {
      imgTst: "img/testimonials/11tst.png",
      name: "Alex lee",
      designation: "Data visualization Project",
      delayAnimation: "1000",
    },
    {
      imgTst: "img/testimonials/12tst.png",
      name: "Tcmtong",
      designation: "Data visualization Project",
      delayAnimation: "1100",
    },
    {
      imgTst: "img/testimonials/13tst.png",
      name: "Tstenko",
      designation: "Data visualization Project",
      delayAnimation: "1200",
    },
  ];

  return (
    <Slider {...settings}>
      {testimonialContent.map((val, i) => (
          <li
            key={i}
            data-aos="fade-right"
           className="whitebg"
            data-aos-duration="1200"
            data-aos-delay={val.delayAnimation}
          >
            <div className="list_inner">
              <div className="details">
                <div className="author">
                  <div className="image"></div>

                  <div className="short">
                    <h3>{val.name}</h3>
                    <span>{val.designation}</span>
                  </div>
                </div>

                <div className="icon">
                  <img className="svg" src="img/svg/quote.svg" alt="quote" />
                </div>
              </div>
              <img src={val.imgTst} />
            </div>
          </li>
      ))}
    </Slider>
  );
}

import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from "react-modal";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
const MobileAppsPortfolio = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState({
    folder: "/pawsNeeds",
    numOfImages: 17,
  });
  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  var settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          arrow: false,
          autoplay: false,
          draggable: true,
          speed: 300,
          dots: true,
        },
      },
    ],
  };
  const customArrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 40,
    height: 40,
    cursor: "pointer",
    opacity: 0.8,
  };

  return (
    <div className="portfolio_inner my_carousel gallery_zoom">
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal1"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="edina_tm_modalbox">
          <button className="close-modal" onClick={toggleModalOne}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner1">
            <div className="description_wrap1">
              <Carousel
                showIndicators={true}
                showThumbs={false}
                swipeable={true}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                  const indicatorStyles = {
                    background: isSelected ? "#000" : "#ddd", // Always Light grey
                    borderRadius: "50%", // Rounded
                    width: "8px",
                    height: "8px",

                    display: "inline-block",
                    margin: "-10px 4px",

                    position: "relative",
                  };

                  return (
                    <li
                      style={indicatorStyles}
                      onClick={onClickHandler}
                      onKeyDown={onClickHandler}
                      value={index}
                      key={index}
                      role="button"
                      tabIndex={0}
                      title={`${label} ${index + 1}`}
                      aria-label={`${label} ${index + 1}`}
                    ></li>
                  );
                }}
                renderArrowPrev={(clickHandler, hasPrev) =>
                  hasPrev && (
                    <div
                      onClick={clickHandler}
                      style={{ ...customArrowStyles, left: -5 }}
                    >
                      <img src="/img/left.png" />
                    </div>
                  )
                }
                renderArrowNext={(clickHandler, hasNext) =>
                  hasNext && (
                    <div
                      onClick={clickHandler}
                      style={{ ...customArrowStyles, right: -5 }}
                    >
                      <img src="/img/right.png" />
                    </div>
                  )
                }
                showArrows={true}
              >
                {Array(selectedProject.numOfImages)
                  .fill("")
                  .map((el, i) => (
                    <div>
                      <img
                        style={{
                          width: "auto",
                          height: "70vh",
                          marginInline: "auto",
                          border: "1px solid black",

                          borderRadius: "15px",
                        }}
                        src={`img/portfolio/${selectedProject.folder}/${
                          i + 1
                        }.jpg`}
                        data-tip
                      />
                    </div>
                  ))}
              </Carousel>
            </div>
          </div>
        </div>
      </Modal>
      <ul
        data-aos="fade-right"
        style={{ paddingInline: "20px" }}
        data-aos-duration="1200"
      >
        <Slider {...settings}>
          <li>
            <div className="list_inner">
              <div
                className="image"
                onClick={() => {
                  setSelectedProject({ folder: "/pawsNeeds", numOfImages: 17 });
                  toggleModalOne();
                }}
              >
                <img
                  src="img/portfolio/6.jpg"
                  style={{
                    maxHeight: "500px",
                    width: "auto",
                    marginInline: "auto",
                  }}
                  data-tip
                  data-for="pawsneeds"
                  alt="portfolio"
                />
                <ReactTooltip
                  id="pawsneeds"
                  place="bottom"
                  type="light"
                  effect="float"
                  className="tooltip-wrapper"
                >
                  <div>
                    <h5>Paws Needs</h5>
                    <span>Pets Services and products</span>
                  </div>
                </ReactTooltip>
              </div>
            </div>
          </li>
          <li>
            <div className="list_inner">
              <div
                className="image"
                onClick={() => {
                  setSelectedProject({ folder: "/ottertools", numOfImages: 5 });
                  toggleModalOne();
                }}
              >
                <img
                  src="img/portfolio/8.jpg"
                  style={{
                    maxHeight: "500px",
                    width: "auto",
                    marginInline: "auto",
                  }}
                  data-tip
                  data-for="ottertools"
                  alt="portfolio"
                />
                <ReactTooltip
                  id="ottertools"
                  place="bottom"
                  type="light"
                  effect="float"
                  className="tooltip-wrapper"
                >
                  <div>
                    <h5>OtterTools</h5>
                    <span>Router template calculator</span>
                  </div>
                </ReactTooltip>
              </div>
            </div>
          </li>
          <li>
            <div className="list_inner">
              <div
                className="image"
                onClick={() => {
                  setSelectedProject({ folder: "/dosap", numOfImages: 8 });
                  toggleModalOne();
                }}
              >
                <img
                  src="img/portfolio/9.jpg"
                  style={{
                    maxHeight: "500px",
                    width: "auto",
                    marginInline: "auto",
                  }}
                  data-tip
                  data-for="dosapp"
                  alt="portfolio"
                />
                <ReactTooltip
                  id="dosapp"
                  place="bottom"
                  type="light"
                  effect="float"
                  className="tooltip-wrapper"
                >
                  <div>
                    <h5>Dos</h5>
                    <span>Dose tracking app</span>
                  </div>
                </ReactTooltip>
              </div>
            </div>
          </li>
     
          <li></li>
        </Slider>
      </ul>
    </div>
  );
};

export default MobileAppsPortfolio;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
emailjs.init("dsl34BQIHDHgUah3V");

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [buttonText, setButtonText] = useState("Send Email");

  const onSubmit = (data, e) => {
    setButtonText("Sending...");

    emailjs
      .sendForm("service_o965f94", "template_h60pwxp", e.target)
      .then((response) => {
        console.log("Success", response);
        setButtonText("Send Email");
        e.target.reset();
      })
      .catch((error) => {
        console.log("Error", error);
        setButtonText("Send Email");
      });
  };
  return (
    <>
      <form className="contact_form" onSubmit={handleSubmit(onSubmit)}>
        <div className="first_row">
          <input
            {...register("name", { required: true })}
            type="text"
            placeholder="Name *"
          />
          {errors.name && errors.name.type === "required" && (
            <span className="invalid-feedback">Name is required</span>
          )}
        </div>
        {/* End .first_row */}

        <div className="second">
          <input
            {...register(
              "email",
              {
                required: "Email is Required",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format",
                },
              },
              { required: true }
            )}
            type="email"
            placeholder="Email *"
          />
          {errors.email && (
            <span className="invalid-feedback">{errors.email.message}</span>
          )}
        </div>
        {/* End .second */}

        <div className="third">
          <textarea
            {...register("message", { required: true })}
            placeholder="Message *"
          ></textarea>
          {errors.message && (
            <span className="invalid-feedback">Message is required</span>
          )}
        </div>
        {/* End .third */}

        <div className="edina_tm_button">
          <div className="edina_tm_button">
            <button type="submit" className="color">
              {buttonText}
            </button>
          </div>
        </div>
        {/* End tokyo_tm_button */}
      </form>
      {/* End contact */}
    </>
  );
};

export default Contact;

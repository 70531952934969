import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from "react-modal";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
const Portfolio = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState({
    folder: "/dashboard",
    numOfImages: 15,
  });
  const customArrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 30,
    height: 30,
    cursor: 'pointer',
    opacity: 1
};
  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  var settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 800,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          arrow: false,
          autoplay: false,
          draggable: true,
          speed: 300,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="portfolio_inner my_carousel gallery_zoom">
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal1"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="edina_tm_modalbox">
          <button className="close-modal" onClick={toggleModalOne}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner1" style={{ scrollbarWidth: "0px" }}>
            <div
              className="description_wrap2"
              style={{
                scrollbarWidth: "0px",
              }}
            >
             <Carousel
                showIndicators={true}
                showThumbs={false}
                swipeable={true}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                  const indicatorStyles = {
                    background: isSelected ? "#000" : "#ddd", // Always Light grey
                    borderRadius: "50%", // Rounded
                    width: "8px",
                    height: "8px",

                    display: "inline-block",
                    margin: "-10px 4px",

                    position: "relative",
                  };

                  return (
                    <li
                      style={indicatorStyles}
                      onClick={onClickHandler}
                      onKeyDown={onClickHandler}
                      value={index}
                      key={index}
                      role="button"
                      tabIndex={0}
                      title={`${label} ${index + 1}`}
                      aria-label={`${label} ${index + 1}`}
                    ></li>
                  );
                }}
                renderArrowPrev={(clickHandler, hasPrev) =>
                  hasPrev && (
                    <div
                      onClick={clickHandler}
                      style={{ ...customArrowStyles, left: -5 }}
                    >
                      <img src="/img/left.png" />
                    </div>
                  )
                }
                renderArrowNext={(clickHandler, hasNext) =>
                  hasNext && (
                    <div
                      onClick={clickHandler}
                      style={{ ...customArrowStyles, right: -5 }}
                    >
                      <img src="/img/right.png" />
                    </div>
                  )
                }
                showArrows={true}
              >
                {Array(selectedProject.numOfImages)
                  .fill("")
                  .map((el, i) => (
                    <div>
                      <img
                        style={{
                          width: "95%",
                          maxHeight: "65vh",
                          margin: "auto",
                        }}
                        src={`img/portfolio/${selectedProject.folder}/${
                          i + 1
                        }.png`}
                        data-tip
                      />
                    </div>
                  ))}
              </Carousel>
            </div>
          </div>
        </div>
      </Modal>
      <ul
        data-aos="fade-right"
        style={{ paddingInline: "20px" }}
        data-aos-duration="1200"
      >
        <Slider {...settings}>
          <li>
            <div className="list_inner">
              <div
                className="image"
                onClick={() => {
                  setSelectedProject({ folder: "/dashboard", numOfImages: 15 });
                  toggleModalOne();
                }}
              >
                <img
                  src="img/portfolio/2.png"
                  data-tip
                  data-for="dashboard"
                  alt="portfolio"
                />
                <ReactTooltip
                  id="dashboard"
                  place="bottom"
                  type="light"
                  effect="float"
                  className="tooltip-wrapper"
                >
                  <div>
                    <h5>Dashboard</h5>
                    <span>Management System</span>
                  </div>
                </ReactTooltip>
              </div>
            </div>
          </li>
          <li>
            <div className="list_inner">
              <div
                className="image"
                onClick={() => {
                  setSelectedProject({ folder: "/lms", numOfImages: 12 });
                  toggleModalOne();
                }}
              >
                <img
                  src="img/portfolio/3.png"
                  data-tip
                  data-for="lms"
                  alt="portfolio"
                />
                <ReactTooltip
                  id="lms"
                  place="bottom"
                  type="light"
                  effect="float"
                  className="tooltip-wrapper"
                >
                  <div>
                    <h5>LMS</h5>
                    <span>Learning Management System</span>
                  </div>
                </ReactTooltip>
              </div>
            </div>
          </li>
          <li>
            <div className="list_inner">
              <div
                className="image"
                onClick={() => {
                  setSelectedProject({
                    folder: "/project manager",
                    numOfImages: 7,
                  });
                  toggleModalOne();
                }}
              >
                <img
                  src="img/portfolio/4.png"
                  data-tip
                  data-for="projectsmanager"
                  alt="portfolio"
                />
                <ReactTooltip
                  id="projectsmanager"
                  place="bottom"
                  type="light"
                  effect="float"
                  className="tooltip-wrapper"
                >
                  <div>
                    <h5>Projects Manager</h5>
                    <span>Projects Management System</span>
                  </div>
                </ReactTooltip>
              </div>
            </div>
          </li>
          <li>
            <div className="list_inner">
              <div
                className="image"
                onClick={() => {
                  setSelectedProject({ folder: "/bluevoyant", numOfImages: 7 });
                  toggleModalOne();
                }}
              >
                <img
                  src="img/portfolio/5.png"
                  data-tip
                  data-for="bluevoyant"
                  alt="portfolio"
                />
                <ReactTooltip
                  id="bluevoyant"
                  place="bottom"
                  type="light"
                  effect="float"
                  className="tooltip-wrapper"
                >
                  <div>
                    <h5>Data visualization</h5>
                    <span>Data visualization and filtering system</span>
                  </div>
                </ReactTooltip>
              </div>
            </div>
          </li>
        </Slider>
      </ul>
    </div>
  );
};

export default Portfolio;

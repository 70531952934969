import React from "react";

const Map = () => {
  return (
    <>
      <div className="google-map">
        <iframe
          className="embed-responsive-item"
          title="my location"
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1475885.7767274762!2d9.527146824152197!3d36.32422663482601!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2stn!4v1695765876442!5m2!1sen!2stn"
        ></iframe>
      </div>
      {/* End google-map */}
    </>
  );
};

export default Map;

import React from "react";

const experienceContent = [
  {
    year: "Jan 2023 - Present",
    position: "Full Stack Web Developer",
    compnayName: "Football ai research",
    details: `In an Agile environment, I developed a responsive website using React.js and Node.js, integrating Stripe for payments and Auth0 for authentication. My role also included managing media via Google Cloud Storage, collaborating on UX/UI design, and employing clean coding practices for maintainability.`,
  },
  {
    year: "June 2022 - Dec 2022",
    position: "Full Stack Mobile Developer",
    compnayName: "iiga studio",
    details: `I engineered React Native apps from scratch, leveraging Node.js for backend services and managing deployments on Google GCP. Responsible for the full application lifecycle on both iOS and Android, my duties also encompassed ongoing maintenance and incremental feature updates.`,
  },
  {
    year: "Dec 2021 - Jan 2023",
    position: "FreeLancer",
    compnayName: "Fiverr",
    details: `During my freelance tenure, I've successfully delivered a diverse array of projects employing technologies such as React.js and React Native. Select projects have benefited from AWS, serverless microservices, i18n for internationalization, and Socket.io for real-time features.`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <img src="img/about/briefcase.png" alt="icon" />
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;

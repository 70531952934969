import React from "react";

const ModalTwo = () => {
  return (
    <div className="popup_informations">
      <div className="image">
        <img src="img/thumbs/4-3.jpg" alt="" />
        <div
          className="main"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/service/2.jpg"
            })`,
          }}
        ></div>
      </div>
      {/* End big image */}

      <div className="description">
        <h3>Mobile App Development Breakdown</h3>
        <p>
          In the fast-paced realm of mobile technology, developing a standout
          app requires more than just good code. It demands a comprehensive
          approach that combines robust functionality with exceptional user
          experience. Below are crucial points that every mobile app developer
          needs to consider.
        </p>
        <h3>Cross-Platform Compatibility</h3>
        <p>
          As Android and iOS dominate the market, the ability to develop
          cross-platform apps is indispensable. Leveraging frameworks like React
          Native or Flutter ensures that your application can reach a broader
          audience without sacrificing quality.
        </p>
        <h3>Focus on User Experience</h3>
        <p>
          A smooth, intuitive user interface can make or break an app. Attention
          to UX principles not only enhances user engagement but also
          contributes to higher retention rates.
        </p>
        <h3>Resource Optimization</h3>
        <p>
          Mobile devices have varying processing capabilities and battery life.
          As a developer, it's essential to optimize the app's performance to
          ensure minimal battery consumption and smooth operation across all
          devices.
        </p>
        <h3>App Deployment and Distribution</h3>
        <p>
          Launching your app successfully involves more than just development;
          it extends to deploying it on platforms like the App Store for iOS and
          Google Play for Android. Understanding the nuances of each platform’s
          submission guidelines and update procedures is a must for any mobile
          app developer.
        </p>
        <h3>Codebase Maintenance</h3>
        <p>
          Just like in web development, writing clean, maintainable code is
          vital. The easier it is to update or extend an app's features, the
          more sustainable its lifecycle will be.
        </p>

        <p>
          These focal points highlight the complex nature of mobile app
          development, drawing attention to the need for a diverse skill
          set—from coding and design to data management and performance tuning.
        </p>
      </div>
    </div>
  );
};

export default ModalTwo;
